<template>
  <div class="booking" @click="clicked">
    <div v-show="showList">
      <div class="top x-title">
        {{ title }}
      </div>
      <!--Date-->
      <div class="dateContent">
        <v-select
          @change="selectBookingType"
          class="bookingType"
          :items="bookingTypes"
          v-model="bookingType"
          :label="selectLabel"
          dense
          outlined
          style="width: 200px"
        ></v-select>

        <!--<datePickNew class="datePick" :date="date" @clickDate="clickDate"></datePickNew>-->
        <datePick
          ref="datePick"
          class="datePick"
          :date="date"
          @clickDate="clickDate"
          v-show="showDatePick"
        ></datePick>
      </div>
      <!--Empty-->
      <div class="bookingTips" v-show="getBookingType == ''">
        <p>
          Please select a {{ tip }} by clicking the button above to see your
          bookings of that type. Select a calendar date to see bookings on that
          date. Calendar dates are only displayed for dates on which you have
          booking(s).
        </p>
      </div>
      <!--Desk List-->
      <div v-show="showDesksList" class="content" ref="scrollContainer">
        <bookingList
          v-for="(item, index) in desksDataFilter"
          :deskLocationC="index + 2 * index + 2 + date.length"
          :listIndex="index + 2 * index + 2 + date.length"
          :checkIndex="index + 2 * index + 3 + date.length"
          :deskLocationIndex="index + 2 * index + 4 + date.length"
          :deskCancelIndex="index + 2 * index + 5 + date.length"
          :data="item"
          :showCheckIn="true"
          :width="listWidth"
          :key="'desk' + index"
          @clickLocation="clickLocation(item)"
          @checkIn="checkIn(item)"
          style="margin-right: 2rem"
          @cancelDesk="cancelDesk(item)"
        ></bookingList>
      </div>
      <!--Room List-->
      <div v-show="showRoomsList" class="content" ref="scrollContainer">
        <bookingList
          v-for="(item, index) in roomsDataFilter"
          :data="item"
          :showCheckIn="true"
          :width="'25rem'"
          :key="'desk' + index"
          @checkIn="checkIn(item)"
          @clickLocation="clickLocation(item)"
          style="margin-right: 2rem"
        ></bookingList>
      </div>
      <!--Other List-->
      <div v-show="showOthersList" class="content" ref="scrollContainer">
        <bookingList
          v-for="(item, index) in othersDataFilter"
          :deskLocationIndex="index + 2 * index + 2 + date.length"
          :deskCancelIndex="index + 2 * index + 3 + date.length"
          :data="item"
          :showCheckIn="false"
          :width="listWidth"
          :key="'desk' + index"
          @clickLocation="clickLocation(item)"
          @checkIn="checkIn(item)"
          style="margin-right: 2rem"
          @cancelDesk="cancelDesk(item)"
        ></bookingList>
      </div>
    </div>
    <bookingMap
      v-if="!showList"
      ref="bookingMap"
      :showRoom="showRoom"
      @back="back"
      @refreshRoom="refreshRoom"
      @checkIn="checkIn(data)"
      @cancelDesk="cancelDesk"
      @refreshOnlineMeeting="refreshOnlineMeeting"
      :data="currentDesk"
      :isOnlineMeeting="currentDesk.isOnlineMeeting"
    ></bookingMap>
    <v-dialog v-model="showRefresh" max-width="290">
      <v-card>
        <v-card-title style="font-size: 18px"> Please Refresh </v-card-title>
        <v-card-text style="font-size: 16px">
          You've been away for a period of time. Please hit the refresh button
          to update the map's availability.
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center">
          <!--<v-spacer></v-spacer>-->
          <v-btn color="green" text @click="ok"> Refresh </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import datePick from "@/views/dashboard/componentsHa/datePick";
import datePickNew from "@/views/dashboard/componentsHa/datePickNew";
import bookingList from "@/views/dashboard/componentsHa/bookingList";
import bookingMap from "@/views/dashboard/spaces/bookingMap";
import { mapState, mapGetters, mapMutations } from "vuex";
import showDelegate from "@/util/delegate";
import displayDesksOffice from "@/util/displayDesksOffice";

export default {
  name: "booking",
  components: {
    datePick,
    datePickNew,
    bookingList,
    bookingMap,
  },
  watch: {
    bookingType(newVal, oldVal) {
      if (!this.firstIn) {
        if (newVal == "Meetings") {
          this.title = "My Meetings";
          this.getDefaultRoomDate();
          this.formatMeetingData();
          this.showRoomsList = true;
          this.showDesksList = false;
          this.showOthersList = false;
        } else if (newVal == "Desks" || newVal == "Desks / Offices") {
          // if (localStorage.getItem('client') == 'WarnerMedia') {
          //   this.title = 'My Desks / Offices';
          // } else {
          //   this.title = 'My Desks';
          // }
          if (displayDesksOffice) {
            this.title = "My Desks / Offices";
          } else {
            this.title = "My Desks";
          }
          this.getDefaultDeskDate();
          this.formatDeskData();
          this.showRoomsList = false;
          this.showDesksList = true;
          this.showOthersList = false;
        } else if (newVal == "Others") {
          console.log("====Others!!!!!!!!!!! =====");
          this.title = "Others";
          this.getDefaultOtherDate();
          this.formatOtherData();
          this.showRoomsList = false;
          this.showDesksList = false;
          this.showOthersList = true;
        }
      }
    },
    showRefresh(val) {
      if (val) {
        this.stopInterval();
      } else {
        this.refreshAll();
      }
    },
  },
  computed: {
    ...mapGetters("bookingType", ["getBookingType"]),

    // getBookingType: {
    //   get() {
    //     console.log("this.$store.state.bookingType:",this.$store.state.bookingType)
    //     return this.$store.state.bookingType;
    //   },
    //   set(newValue) {
    //     this.$store.state.bookingType = newValue;
    //   },
    // }
  },
  data() {
    return {
      timezone: "",
      isDisplayDesksOffice: false,
      showOthers: false, //左上方select选项有无Others
      listWidth: "",
      attendees: [],
      tip: "",
      selectLabel: "",
      showRefresh: false,
      timeout: null,
      firstIn: true,
      startTime: 0,
      endTime: 0,
      showRoomsList: false,
      showDesksList: false,
      showOthersList: false,
      showRoom: true,
      title: "",
      currentDesk: {}, //当前选中的desk
      showList: true,
      // deskList
      desksData: [],
      desksDataFilter: [], //desk list 经过日期筛选
      othersData: [],
      othersDataFilter: [],
      // roomList
      roomsData: [],
      roomsDataFilter: [], //meeting list经过日期筛选
      // 日期
      date: [
        // {day: 'MON', month: 'FEB', date: 1, actived: true},
        // {day: 'TUE', month: 'FEB', date: 2, actived: false},
        // {day: 'WED', month: 'FEB', date: 3, actived: false},
        // {day: 'THU', month: 'FEB', date: 4, actived: false},
        // {day: 'FRI', month: 'FEB', date: 5, actived: false},
        // {day: 'SAT', month: 'FEB', date: 6, actived: false},
        // {day: 'SUN', month: 'FEB', date: 7, actived: false},
      ],
      bookingTypes: ["Meetings", "Desks"],
      bookingType: "",
      showDatePick: false,
      bookingDateArr: [],
    };
  },
  methods: {
    ...mapMutations("bookingType", ["setBookingType"]),
    startInterval() {
      // console.log("开始倒计时")
      this.timeout = window.setInterval(() => {
        this.showRefresh = true;
        // this.$confirm({title: "Please Refresh", message: "You've been away for a period of time. Please hit the refresh button to update the map's availability.", show: true});
      }, 2 * 60 * 1000);
    },
    stopInterval() {
      // console.log("停止计时")
      if (this.timeout) {
        window.clearInterval(this.timeout);
        this.timeout = null;
      }
    },
    ok() {
      this.showRefresh = false;
    },
    clicked() {
      if (this.timeout) {
        this.stopInterval();
        this.startInterval();
      }
    },
    selectBookingType(type) {
      console.log("type:", type);
      this.setBookingType(type);
      // if (window.gtag) {
      //   window.gtag("event", "Booking", {
      //     event_category: "Booking Type",
      //     event_label: type,
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || "",
      //   });
      // }
      this.$gtag.event('Booking', {
        event_category: "Booking Type",
        event_label: type,
        send_to: localStorage.getItem("googleAnaId") || "",
        dimension1: localStorage.getItem("campusName") || "",
      });
    },
    // 时间戳换算成时间（测试用来校验）
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000)
        .toLocaleString()
        .replace(/:\d{1,2}$/, " ");
    },
    // 获取当前时间
    getCurrentDate() {
      let d = new Date();
      d.setSeconds(0);
      let day = moment(d).format("dddd");
      let month = moment(d).format("MMM");
      let date = moment(d).format("D");

      // let day = d.split(" ")[0];
      // let month = d.split(" ")[1];
      // let date = d.split(" ")[2];
      this.title =
        "Today's Date : " + day + "\xa0\xa0\xa0" + month + " " + date;
    },
    //deskList左右滑动
    MouseWheel(e) {
      console.log("s");
      let eventDelta = -e.wheelDelta || -e.deltaY * 40;

      let $scrollWrapper = this.$refs.scrollContainer;

      // console.log($scrollWrapper.scrollLeft)

      // 0到scrollLeft为滚动区域隐藏部分

      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 2;
    },
    back(data) {
      console.log("data-booking:", data);
      let that = this;
      this.showList = true;
      if (data.type == "desk") {
        that.getUserSeatsReturn().then((res) => {
          that.bus.$emit("loading", false);
          that.desksData = res.data.data;
          if (that.desksData.length == 0) {
            that.date = [];
            that.desksDataFilter = [];
            return;
          } else {
            that.getDeskDate();
            let length = this.desksData.length;
            for (let i = 0; i < length; i++) {
              let item = this.desksData[i];
              if (item.eventId == data.eventId) {
                this.desksData.splice(i, 1);
                break;
              }
            }
            that.formatDeskData();
            that.bus.$emit("loading", false);
            that.$message.success("Success");
          }
        });
      } else if (data.type == "meeting") {
        let length = this.roomsData.length;
        for (let i = 0; i < length; i++) {
          let item = this.roomsData[i];
          if (item.eventId == data.eventId) {
            this.roomsData.splice(i, 1);
            break;
          }
        }
        this.firstIn = false;
        this.formatMeetingData();
        this.bus.$emit("loading", false);
        this.$message.success("Success");

        // that.listEventReturn().then(res=>{
        //   that.firstIn = false;
        //   that.bus.$emit('loading', false);
        //   that.roomsData = res.data.data;
        //   that.formatMeetingData();
        //   that.$message.success("Success");
        // })
      } else {
        return;
      }
    },
    getUserSeatsReturn() {
      return this.$axios({
        // method: 'post',
        // url: '/m/v2/desk/getUserSeats',
        method: this.$usev2 ? "post" : "get",
        url: this.$usev2 ? "/m/v2/desk/getUserSeats" : "/m/desk/getUserSeats",
        data: {
          email: localStorage.userEmail,
          meetingId: localStorage.meetingIdMap,
          // token: localStorage.token,
        },
      });
    },
    // 获取用户预定的room
    listEvent(refreshDate = true) {
      this.$axios({
        // method: 'post',
        // url: '/m/v2/spaces/listEvent/',
        method: this.$usev2 ? "post" : "get",
        url: this.$usev2 ? "/m/v2/spaces/listEvent/" : "/m/spaces/listEvent/",
        data: {
          calendarId: localStorage.userEmail,
          meetingId: localStorage.meetingIdMap,
          // token: localStorage.token
        },
      })
        .then((res) => {
          this.firstIn = false;
          this.bus.$emit("loading", false);
          this.roomsData = res.data.data;
          this.formatMeetingData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listEventReturn() {
      return this.$axios({
        // method: 'post',
        // url: '/m/v2/spaces/listEvent/',
        method: this.$usev2 ? "post" : "get",
        url: this.$usev2 ? "/m/v2/spaces/listEvent/" : "/m/spaces/listEvent/",
        data: {
          calendarId: localStorage.userEmail,
          meetingId: localStorage.meetingIdMap,
          // token: localStorage.token
        },
      });
    },
    // 获取帮他人预订的桌子列表
    delegateBookingReturn() {
      if (!this.showOthers) {
        return new Promise((resolve) => {
          resolve(null);
        });
      }
      return this.$axios({
        method: "get",
        url: "/m/spaces/delegateBooking",
        data: {
          type: "desk",
        },
      });
    },
    // 根据日期筛选显示的room
    formatMeetingData() {
      let length = this.date.length;
      console.log("this.date123", this.date);
      let highLight = {};
      for (let i = 0; i < length; i++) {
        let item = this.date[i];
        if (item.actived) {
          highLight = item;
          break;
        } else {
          continue;
        }
      }
      console.log("highLight！！！！:", highLight);
      console.log("roomsData:", this.roomsData);
      let arr = [];
      if (this.roomsData != undefined) {
        this.roomsData.map((item) => {
          console.log("item:", item);
          let itemStartUnix = moment(moment.utc(item.startTime)).unix();
          let itemEndUnix = moment(moment.utc(item.endTime)).unix();
          let highLightStartUnix = highLight.timeStampStart;
          let highLightEndUnix = highLight.timeStampEnd;
          console.log("itemStartUnix:", itemStartUnix);
          console.log("itemEndUnix:", itemEndUnix);
          console.log("highLightStartUnix:", highLightStartUnix);
          console.log("highLightEndUnix:", highLightEndUnix);
          if (itemStartUnix > highLightEndUnix) {
            console.log("走1");
            return;
          } else if (
            itemStartUnix < highLightStartUnix &&
            itemEndUnix < highLightStartUnix
          ) {
            console.log("走2");
            return;
          } else {
            console.log("走3");
            item.itemStartUnix = itemStartUnix;
            item.itemEndUnix = itemEndUnix;
            arr.push(item);
          }
        });
      }
      if (this.bookingType == "Meetings") {
        this.showRoomsList = false;
        this.roomsDataFilter = arr;
        this.showRoomsList = true;
        console.log("roomsDataFilter:", this.roomsDataFilter);
      }
      // this.roomsData.map(item => {
      //   let itemStart = moment(item.startTime).unix();
      //   let itemEnd = moment(item.endTime).unix();
      // })
    },
    changeTimezone(type = "desk") {
      console.log("type123", type);
      let timezone = localStorage.getItem("timezone");
      if (timezone) {
        if (type == "room") {
          console.log("执行了room");
          if (this.roomsData.length > 0) {
            this.roomsData.forEach((item) => {
              let zeroTimeZoneUnixStart = moment
                .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
                .unix();
              let zeroTimeZoneUnixEnd = moment
                .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
                .unix();
              let meetingTimeZoneStartString = moment
                .tz(zeroTimeZoneUnixStart, "X", timezone)
                .format("YYYY-MM-DD HH:mm:ss");
              let meetingTimeZoneEndString = moment
                .tz(zeroTimeZoneUnixEnd, "X", timezone)
                .format("YYYY-MM-DD HH:mm:ss");
              // console.log('meetingTimeZoneStartString:',meetingTimeZoneStartString);
              // console.log('meetingTimeZoneEndString:',meetingTimeZoneEndString);
              item.start = meetingTimeZoneStartString;
              item.end = meetingTimeZoneEndString;
              item.zeroTimeZoneUnixStart = zeroTimeZoneUnixStart;
              item.zeroTimeZoneUnixEnd = zeroTimeZoneUnixEnd;
            });
          }
        } else if (type == "desk") {
          console.log("执行了desk");
          console.log("this.desksData", this.desksData);
          if (this.desksData.length > 0) {
            this.desksData.forEach((item) => {
              let zeroTimeZoneUnixStart = moment
                .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
                .unix();
              let zeroTimeZoneUnixEnd = moment
                .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
                .unix();
              let meetingTimeZoneStartString = moment
                .tz(zeroTimeZoneUnixStart, "X", timezone)
                .format("YYYY-MM-DD HH:mm:ss");
              let meetingTimeZoneEndString = moment
                .tz(zeroTimeZoneUnixEnd, "X", timezone)
                .format("YYYY-MM-DD HH:mm:ss");
              // console.log('meetingTimeZoneStartString:',meetingTimeZoneStartString);
              // console.log('meetingTimeZoneEndString:',meetingTimeZoneEndString);
              item.start = meetingTimeZoneStartString;
              item.end = meetingTimeZoneEndString;
              item.zeroTimeZoneUnixStart = zeroTimeZoneUnixStart;
              item.zeroTimeZoneUnixEnd = zeroTimeZoneUnixEnd;
            });
          }
        } else if (type == "others") {
          console.log("执行了others");
          console.log(" this.othersData", this.othersData);
          if (this.othersData.length > 0) {
            this.othersData.forEach((item) => {
              console.log("itemitemitemitemitemitemitem", item);
              let zeroTimeZoneUnixStart = moment
                .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
                .unix();
              let zeroTimeZoneUnixEnd = moment
                .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
                .unix();
              let meetingTimeZoneStartString = moment
                .tz(zeroTimeZoneUnixStart, "X", timezone)
                .format("YYYY-MM-DD HH:mm:ss");
              let meetingTimeZoneEndString = moment
                .tz(zeroTimeZoneUnixEnd, "X", timezone)
                .format("YYYY-MM-DD HH:mm:ss");
              // console.log('meetingTimeZoneStartString:',meetingTimeZoneStartString);
              // console.log('meetingTimeZoneEndString:',meetingTimeZoneEndString);
              item.start = meetingTimeZoneStartString;
              item.end = meetingTimeZoneEndString;
              item.zeroTimeZoneUnixStart = zeroTimeZoneUnixStart;
              item.zeroTimeZoneUnixEnd = zeroTimeZoneUnixEnd;
            });
          }
        }
      } else {
        return;
      }
    },
    // 根据日期筛选显示的desk
    formatDeskData() {
      console.log("执行formatDeskData---------------");
      console.log("desksData:", this.desksData);
      let today = new Date();
      today.setSeconds(0);
      let todayStartUnix = moment(today).startOf("day").unix();
      let length = this.date.length;
      let highLight = {};
      for (let i = 0; i < length; i++) {
        let item = this.date[i];
        if (item.actived) {
          highLight = item;
          break;
        } else {
          continue;
        }
      }
      console.log("highLightDesk！！！！:", highLight);
      let arrKey = Object.keys(highLight);
      console.log("arrKey：", arrKey);
      let arr = [];
      if (arrKey.length > 0) {
        let timezone = localStorage.getItem("timezone");
        this.desksData.map((item) => {
          let itemStartUnix = timezone
            ? moment.tz(item.start, "YYYY-MM-DD HH:mm:ss", timezone).unix()
            : moment(moment.utc(item.start)).unix();
          let itemEndUnix = timezone
            ? moment.tz(item.end, "YYYY-MM-DD HH:mm:ss", timezone).unix()
            : moment(moment.utc(item.end)).unix();
          let highLightStartUnix = highLight.timeStampStart;
          let highLightEndUnix = highLight.timeStampEnd;
          console.log("itemStartUnix：", itemStartUnix);
          console.log("highLightStartUnix：", highLightStartUnix);
          console.log("highLightEndUnix", highLightEndUnix);

          if (itemStartUnix > highLightEndUnix) {
            console.log("1");
            return;
          } else if (
            itemStartUnix < highLightStartUnix &&
            itemEndUnix < highLightStartUnix
          ) {
            return;
          } else {
            console.log("2");
            item.itemStartUnix = itemStartUnix;
            item.itemEndUnix = itemEndUnix;
            arr.push(item);
          }
        });
      }
      if (
        this.bookingType == "Desks" ||
        this.bookingType == "Desks / Offices"
      ) {
        this.showDesksList = false;
        this.desksDataFilter = arr;
        this.showDesksList = true;
        console.log("desksDataFilter:", this.desksDataFilter);
      }
    },
    // formatOtherData() {
    //   console.log("formatOtherData:", this.othersData);
    //   let today = new Date();
    //   let todayStartUnix = moment(today).startOf('day').unix();
    //   let length = this.date.length;
    //   let highLight = {};
    //   for (let i = 0; i < length; i++) {
    //     let item = this.date[i];
    //     if (item.actived) {
    //       highLight = item;
    //       break
    //     } else {
    //       continue
    //     }
    //   }
    //   console.log("highLightDesk！！！！:", highLight);
    //   let arrKey = Object.keys(highLight);
    //   let arr = [];
    //   if (arrKey.length > 0) {
    //     this.othersData.map(item => {
    //       console.log('othersData',item);
    //       // let itemStartUnix = moment(item.start).unix();
    //       // let itemEndUnix = moment(item.end).unix();
    //       // let itemStartUnix = moment(moment.utc(item.start)).unix();
    //       // let itemEndUnix = moment(moment.utc(item.end)).unix();
    //       let itemStartUnix = moment.tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan").unix();
    //       let itemEndUnix = moment.tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan").unix();
    //       let highLightStartUnix = highLight.timeStampStart;
    //       let highLightEndUnix = highLight.timeStampEnd;
    //       console.log('itemStartUnix:',itemStartUnix);
    //       console.log('itemEndUnix:',itemEndUnix);
    //       console.log('highLightStartUnix:',highLightStartUnix);
    //       console.log('highLightEndUnix:',highLightEndUnix);
    //
    //       if (itemStartUnix > highLightEndUnix) {
    //         return
    //       } else if (itemStartUnix < highLightStartUnix && itemEndUnix < highLightStartUnix) {
    //         return
    //       } else {
    //         arr.push(item);
    //       }
    //     })
    //   }
    //   if (this.bookingType == 'Others') {
    //     this.showOthersList = false;
    //     this.othersDataFilter = arr;
    //     this.showOthersList = true;
    //     console.log("othersDataFilter:", this.othersDataFilter);
    //   }
    // },
    formatOtherData() {
      console.log("othersData:", this.othersData);
      let today = new Date();
      today.setSeconds(0);
      let todayStartUnix;
      if (this.timezone) {
        todayStartUnix = moment.tz(today, this.timezone).startOf("day").unix();
      } else {
        todayStartUnix = moment(today).startOf("day").unix();
      }
      // let todayStartUnix = moment(today).startOf('day').unix();
      let length = this.date.length;
      let highLight = {};
      for (let i = 0; i < length; i++) {
        let item = this.date[i];
        if (item.actived) {
          highLight = item;
          break;
        } else {
          continue;
        }
      }
      console.log("highLightDesk！！！！:", highLight);
      let arrKey = Object.keys(highLight);

      let arr = [];
      if (arrKey.length > 0) {
        let timezone = localStorage.getItem("timezone");
        this.othersData.map((item) => {
          let itemStartUnix = timezone
            ? moment.tz(item.zeroTimeZoneUnixStart, "X", timezone).unix()
            : moment(moment.utc(item.start)).unix();
          let itemEndUnix = timezone
            ? moment.tz(item.zeroTimeZoneUnixEnd, "X", timezone).unix()
            : moment(moment.utc(item.end)).unix();
          let highLightStartUnix = highLight.timeStampStart;
          let highLightEndUnix = highLight.timeStampEnd;
          item.itemStartUnix = itemStartUnix;
          item.itemEndUnix = itemEndUnix;
          console.log("itemStartUnix00:", itemStartUnix);
          console.log("itemEndUnix00:", itemEndUnix);
          if (itemStartUnix > highLightEndUnix) {
            return;
          } else if (
            itemStartUnix < highLightStartUnix &&
            itemEndUnix < highLightStartUnix
          ) {
            return;
          } else {
            // item.itemStartUnix = itemStartUnix;
            // item.itemEndUnix = itemEndUnix;
            arr.push(item);
          }
        });
      }
      console.log("arr", arr);
      if (this.bookingType == "Others") {
        this.showOthersList = false;
        this.othersDataFilter = arr;
        this.showOthersList = true;
        console.log("othersDataFilter:", this.othersDataFilter);
      }
    },

    getEvent(calendarId, meetingId, eventId) {
      return this.$axios({
        // method: 'post',
        // url: '/m/v2/spaces/getEvent',
        method: this.$usev2 ? "post" : "get",
        url: this.$usev2 ? "/m/v2/spaces/getEvent" : "/m/spaces/getEvent",
        data: {
          calendarId: calendarId,
          meetingId: meetingId,
          eventId: eventId,
          // token: localStorage.token
        },
      });
    },
    // 查看Map
    clickLocation(data) {
      this.bus.$emit("loading", true);
      console.log("clickMap:", data);
      this.currentDesk = data;
      // 往data中加入attendees（之前data是有返的，后来没了，所以要另外请求接口）
      if (data.hasOwnProperty("seat_id")) {
        this.bus.$emit("loading", false);
        this.showRoom = false;
        this.showList = false;
        // if (window.gtag) {
        //   window.gtag("event", "Booking", {
        //     event_category: "Desk Location Click",
        //     event_label: "Desk Location",
        //     // event_label: data.seat_name,
        //     send_to: localStorage.getItem("googleAnaId") || "",
        //     dimension1: localStorage.getItem("campusName") || "",
        //   });
        // }
        this.$gtag.event('Booking', {
          event_category: "Desk Location Click",
          event_label: "Desk Location",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      } else {
        let meetingId = localStorage.getItem("meetingIdMap");
        let calendarId = localStorage.getItem("userEmail");
        let eventId = data.eventId;
        this.getEvent(calendarId, meetingId, eventId)
          .then((res) => {
            this.bus.$emit("loading", false);
            this.currentDesk.attendees = res.data.data.attendees || [];
            console.log("res:", res);
            console.log("attendees:", res.data.data.attendees);
            console.log("currentDesk:", this.currentDesk);
            this.showRoom = true;
            this.showList = false;
            // if (window.gtag) {
            //   window.gtag("event", "Booking", {
            //     event_category: "Meeting Details Click",
            //     event_label: "Meeting Details",
            //     // event_label: data.rooms[0].resourceName,
            //     send_to: localStorage.getItem("googleAnaId") || "",
            //     dimension1: localStorage.getItem("campusName") || "",
            //   });
            // }
            this.$gtag.event('Booking', {
              event_category: "Meeting Details Click",
              event_label: "Meeting Details",
              send_to: localStorage.getItem("googleAnaId") || "",
              dimension1: localStorage.getItem("campusName") || "",
            });
          })
          .catch((err) => {
            this.bus.$emit("loading", false);
          });
      }

      // this.showList = false;
      // this.currentDesk = data;
      // this.currentDesk.attendees = res.data.data.attendees || [];
      // console.log("res:",res);
      // console.log("attendees:",res.data.data.attendees);
      // console.log("currentDesk:",this.currentDesk);
      // if (data.hasOwnProperty('seat_id')) {
      //   this.showRoom = false;
      //   if (window.gtag) {
      //     window.gtag('event', 'Booking', {
      //       event_category: 'Desk Location Click',
      //       event_label: 'Desk Location',
      //       // event_label: data.seat_name,
      //       send_to: localStorage.getItem("googleAnaId") || "",
      //       dimension1: localStorage.getItem("campusName") || ""
      //     })
      //   }
      //
      // }
      // else {
      //   this.showRoom = true;
      //   if (window.gtag) {
      //     window.gtag('event', 'Booking', {
      //       event_category: 'Meeting Details Click',
      //       event_label: 'Meeting Details',
      //       // event_label: data.rooms[0].resourceName,
      //       send_to: localStorage.getItem("googleAnaId") || "",
      //       dimension1: localStorage.getItem("campusName") || ""
      //     })
      //   }
      //
      // }
      // this.$router.push({name:'bookingMap',params:{meetingId:meetingId}})
    },
    // check in
    checkIn(data) {
      console.log("==== checkIn123=====", data);
      this.$axios({
        // method: 'post',
        // url: '/m/v2/spaces/checkIn',
        method: this.$usev2 ? "post" : "get",
        url: this.$usev2 ? "/m/v2/spaces/checkIn" : "/m/spaces/checkIn",
        data: {
          reservationId: data.reservation_id,
          type: data.type,
        },
      })
        .then((res) => {
          console.log("res:", res);
          this.$message.success("Success");
          if (data.type == "desk") {
            if (this.bookingType == "Others") {
              let length = this.othersData.length;
              for (let i = 0; i < length; i++) {
                let item = this.othersData[i];
                if (item.reservation_id == data.reservation_id) {
                  // this.desksData.splice(i, 1);
                  console.log(
                    "==== this.othersData[i]=====",
                    this.othersData[i]
                  );
                  this.othersData[i].checkIn = true;
                  break;
                }
              }
            } else {
              let length = this.desksData.length;
              for (let i = 0; i < length; i++) {
                let item = this.desksData[i];
                if (item.reservation_id == data.reservation_id) {
                  // this.desksData.splice(i, 1);
                  console.log("==== this.desksData[i]=====", this.desksData[i]);
                  this.desksData[i].checkIn = true;
                  break;
                }
              }
            }
          } else {
            let length = this.roomsData.length;
            for (let i = 0; i < length; i++) {
              let item = this.roomsData[i];
              if (item.eventId == data.eventId) {
                this.roomsData[i].checkIn = true;
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // clickLocation(data) {
    //   this.bus.$emit('loading', true);
    //   console.log("clickMap:", data)
    //   let meetingId = localStorage.getItem('meetingIdMap');
    //   let calendarId = localStorage.getItem('userEmail');
    //   let eventId = data.eventId;
    //   // 往data中加入attendees（之前data是有返的，后来没了，所以要另外请求接口）
    //   this.getEvent(calendarId, meetingId, eventId).then(res => {
    //     this.bus.$emit('loading', false);
    //     this.showList = false;
    //     this.currentDesk = data;
    //     this.currentDesk.attendees = res.data.data.attendees || [];
    //     console.log("res:", res);
    //     console.log("attendees:", res.data.data.attendees);
    //     console.log("currentDesk:", this.currentDesk);
    //     if (data.hasOwnProperty('seat_id')) {
    //       this.showRoom = false;
    //       if (window.gtag) {
    //         window.gtag('event', 'Booking', {
    //           event_category: 'Desk Location Click',
    //           event_label: 'Desk Location',
    //           // event_label: data.seat_name,
    //           send_to: localStorage.getItem("googleAnaId") || "",
    //           dimension1: localStorage.getItem("campusName") || ""
    //         })
    //       }
    //
    //     }
    //     else {
    //       this.showRoom = true;
    //       if (window.gtag) {
    //         window.gtag('event', 'Booking', {
    //           event_category: 'Meeting Details Click',
    //           event_label: 'Meeting Details',
    //           // event_label: data.rooms[0].resourceName,
    //           send_to: localStorage.getItem("googleAnaId") || "",
    //           dimension1: localStorage.getItem("campusName") || ""
    //         })
    //       }
    //
    //     }
    //   }).catch(err => {
    //     this.bus.$emit('loading', false);
    //   });
    //   // this.showList = false;
    //   // this.currentDesk = data;
    //   // this.currentDesk.attendees = res.data.data.attendees || [];
    //   // console.log("res:",res);
    //   // console.log("attendees:",res.data.data.attendees);
    //   // console.log("currentDesk:",this.currentDesk);
    //   // if (data.hasOwnProperty('seat_id')) {
    //   //   this.showRoom = false;
    //   //   if (window.gtag) {
    //   //     window.gtag('event', 'Booking', {
    //   //       event_category: 'Desk Location Click',
    //   //       event_label: 'Desk Location',
    //   //       // event_label: data.seat_name,
    //   //       send_to: localStorage.getItem("googleAnaId") || "",
    //   //       dimension1: localStorage.getItem("campusName") || ""
    //   //     })
    //   //   }
    //   //
    //   // }
    //   // else {
    //   //   this.showRoom = true;
    //   //   if (window.gtag) {
    //   //     window.gtag('event', 'Booking', {
    //   //       event_category: 'Meeting Details Click',
    //   //       event_label: 'Meeting Details',
    //   //       // event_label: data.rooms[0].resourceName,
    //   //       send_to: localStorage.getItem("googleAnaId") || "",
    //   //       dimension1: localStorage.getItem("campusName") || ""
    //   //     })
    //   //   }
    //   //
    //   // }
    //   // this.$router.push({name:'bookingMap',params:{meetingId:meetingId}})
    // },
    // 取消预定(desk or room)
    cancelDesk(data) {
      console.log("data------", data);
      if (data.type == "meeting") {
        let eventId = data.eventId;
        let calendarId = localStorage.userEmail;
        this.$confirm({
          title: "Confirmation",
          message: "Are you sure you would like to cancel this reservation?",
          show: true,
          showTwoBtn: true,
          okMsg: "Confirm",
          cancelMsg: "Go Back",
        })
          .then(() => {
            this.bus.$emit("loading", true);
            //用户点击确认后执行
            this.$axios({
              method: this.$usev2 ? "post" : "get",
              url: this.$usev2
                ? "/m/v2/spaces/deleteEvent/"
                : "/m/spaces/deleteEvent/",
              // url: '/m/v2/spaces/deleteEvent/',
              data: {
                meetingId: localStorage.meetingIdMap,
                eventId: eventId,
                calendarId: calendarId,
                // token: localStorage.token
              },
            })
              .then((res) => {
                // console.log('res:', res);
                let length = this.roomsData.length;
                for (let i = 0; i < length; i++) {
                  let item = this.roomsData[i];
                  if (item.eventId == data.eventId) {
                    this.roomsData.splice(i, 1);
                    break;
                  }
                }
                this.firstIn = false;
                this.formatMeetingData();
                this.bus.$emit("loading", false);
                this.showList = true;
                this.$message.success("Success");
                // if (window.gtag) {
                //   window.gtag("event", "Bookings", {
                //     event_category: "Cancel Meeting Click",
                //     event_label: "Cancel Meeting",
                //     // event_label: data.rooms[0].resourceName,
                //     send_to: localStorage.getItem("googleAnaId") || "",
                //     dimension1: localStorage.getItem("campusName") || "",
                //   });
                // }
                this.$gtag.event('Booking', {
                  event_category: "Cancel Meeting Click",
                  event_label: "Cancel Meeting",
                  send_to: localStorage.getItem("googleAnaId") || "",
                  dimension1: localStorage.getItem("campusName") || "",
                });
              })
              .catch((err) => {
                this.bus.$emit("loading", false);
                console.log(err);
              });
          })
          .catch(() => {
            // 取消或关闭
          });
      } else {
        let reservationId = data.reservation_id;
        console.log("reservationId:", reservationId);
        this.$confirm({
          title: "Confirmation",
          message: "Are you sure you would like to cancel this reservation?",
          show: true,
          showTwoBtn: true,
          okMsg: "Confirm",
          cancelMsg: "Go Back",
        })
          .then(() => {
            this.bus.$emit("loading", true);
            //用户点击确认后执行
            this.$axios({
              // method: 'post',
              // url: '/m/desk/cancelSeat',
              method: this.$usev2 ? "post" : "get",
              url: this.$usev2
                ? "/m/v2/desk/cancelSeat?reservationId=" + reservationId
                : "/m/desk/cancelSeat", //post写法不应该这样，后台代码合并有问题，暂时先兼容一下后台
              data: {
                meetingId: localStorage.meetingIdMap,
                reservationId: reservationId,
                // token: localStorage.token
              },
            })
              .then((res) => {
                if (res.data.result == "failed") {
                  this.bus.$emit("loading", false);
                  this.$confirm({
                    title: "Confirmation",
                    message: res.data.message,
                    show: true,
                  });
                  return;
                }
                this.bus.$emit("loading", false);
                this.$message.success("Success");
                this.showList = true;
                if (window.gtag) {
                  window.gtag("event", "Bookings", {
                    event_category: "Cancel Desk Click",
                    event_label: "Cancel Desk",
                    send_to: localStorage.getItem("googleAnaId") || "",
                    dimension1: localStorage.getItem("campusName") || "",
                  });
                }
                console.log("====desksData =====", this.desksData);
                if (
                  this.bookingType == "Desks" ||
                  this.bookingType == "Desks / Offices"
                ) {
                  let length = this.desksData.length;
                  for (let i = 0; i < length; i++) {
                    let item = this.desksData[i];
                    if (item.reservation_id == data.reservation_id) {
                      // this.desksData.splice(i, 1);
                      this.desksData.splice(i, 1);
                      break;
                    }
                  }
                  if (this.desksData.length == 0) {
                    this.date = [];
                    this.desksDataFilter = [];
                    return;
                  } else {
                    console.log("cancelDesk");
                    this.getDeskDate();
                    this.formatDeskData();
                  }
                } else if (this.bookingType == "Others") {
                  let length = this.othersData.length;
                  for (let i = 0; i < length; i++) {
                    let item = this.othersData[i];
                    if (item.reservation_id == data.reservation_id) {
                      // this.desksData.splice(i, 1);
                      this.othersData.splice(i, 1);
                      break;
                    }
                  }
                  if (this.othersData.length == 0) {
                    this.date = [];
                    this.othersDataFilter = [];
                    return;
                  } else {
                    this.getOthersDate();
                    this.formatOtherData();
                  }
                } else {
                  return;
                }
              })
              .catch((err) => {
                this.bus.$emit("loading", false);
                console.log(err);
              });
          })
          .catch(() => {
            // 取消或关闭
          });
      }
    },
    //更新Add Teams按钮状态（这里玩了一个爷孙组件传值）
    refreshOnlineMeeting(data) {
      console.log("refreshOnlineMeeting", data);
      console.log("this.currentDesk", this.currentDesk);
      this.currentDesk.isOnlineMeeting = data;
    },
    dateFormat(date) {
      return moment(date).unix();
    },
    // 选择日期
    clickDate(data) {
      console.log("data:", data);
      // 改变filter的开始时间和结束时间（删除meeting后重新获取所有meeting，筛选的出来是当前时间段startTime-endTime）
      this.startTime = data.timeStampStart;
      this.endTime = data.timeStampEnd;

      console.log("bookingType:", this.bookingType);
      if (this.bookingType == "Meetings") {
        this.formatMeetingData();
      } else if (this.bookingType == "Others") {
        this.formatOtherData();
      } else {
        this.formatDeskData();
      }
    },
    getRoomDate() {
      let d = new Date();
      d.setSeconds(0);
      let timezone = localStorage.getItem("timezone");
      let arr = [];
      let config = JSON.parse(localStorage.config);
      let displayBookingRoomLimit = config.displayBookingRoomLimit || 7;
      console.log("====displayBookingRoomLimit =====", displayBookingRoomLimit);
      // 生成当前至后7天的日期
      for (let i = 0; i < displayBookingRoomLimit; i++) {
        let obj = {};
        if (timezone) {
          obj.timeStampStart = moment
            .tz(d, timezone)
            .startOf("day")
            .add(+i, "days")
            .unix();
          obj.timeStampEnd = moment
            .tz(d, timezone)
            .endOf("day")
            .add(+i, "days")
            .unix();
          obj.day = moment
            .tz(d, timezone)
            .add(+i, "days")
            .format("ddd");
          obj.month = moment
            .tz(d, timezone)
            .add(+i, "days")
            .format("MMM");
          obj.date = moment
            .tz(d, timezone)
            .add(+i, "days")
            .format("DD");
          obj.actived = false;
        } else {
          obj.timeStampStart = moment(d)
            .startOf("day")
            .add(+i, "days")
            .unix();
          obj.timeStampEnd = moment(d)
            .endOf("day")
            .add(+i, "days")
            .unix();
          obj.day = moment(d)
            .add(+i, "days")
            .format("ddd");
          obj.month = moment(d)
            .add(+i, "days")
            .format("MMM");
          obj.date = moment(d)
            .add(+i, "days")
            .format("DD");
          obj.actived = false;
        }
        // obj.timeStampStart = moment(d).startOf('day').add(+(i), 'days').unix();
        // obj.timeStampEnd = moment(d).endOf('day').add(+(i), 'days').unix();
        // obj.day = (moment(d).add(+(i), 'days').format("ddd"));
        // obj.month = moment(d).add(+(i), 'days').format("MMM");
        // obj.date = moment(d).add(+(i), 'days').format("DD");
        // obj.actived = false;
        arr.push(obj);
      }
      console.log("====arr=====", arr);
      if (this.startTime && this.endTime) {
        console.log(11111);
        // space预定的时间不在这7天内，则展示第一天的metting
        // 开始时间和结束时间都小于当天
        if (this.startTime > arr[displayBookingRoomLimit - 1].timeStampEnd) {
          console.log(222222);
          arr[0].actived = true;
        } else if (
          this.startTime < arr[0].timeStampStart &&
          this.endTime < arr[0].timeStampStart
        ) {
          // 开始时间和结束时间都大于当天
          console.log(3333);
          arr[0].actived = true;
        } else {
          // 开始时间和结束时间和这7天有交集
          console.log(44444);
          let diff = moment(arr[0].timeStampStart, "X").diff(
            moment(this.startTime, "X"),
            "minutes"
          );
          if (diff >= 0) {
            console.log(55555);
            // 开始时间小于当天则高亮当天日期
            arr[0].actived = true;
          } else {
            // console.log(66666)
            // 开始时间大于当天则高亮开始时间
            let u;
            if (timezone) {
              u = moment
                .tz(this.startTime, "X", timezone)
                .startOf("day")
                .unix();
            } else {
              u = moment(this.startTime, "X").startOf("day").unix();
            }
            // let u = moment(this.startTime, "X").startOf('day').unix();
            console.log("u:", u);
            let length = arr.length;
            for (let i = 0; i < length; i++) {
              let item = arr[i];
              if (item.timeStampStart == u) {
                item.actived = true;
                break;
              } else {
                continue;
              }
            }
          }

          // let length = arr.length;
          // for (let i = 0; i < length; i++) {
          //   let item = arr[i];
          //
          //   if (item.timeStampStart == this.startTime) {
          //     item.actived = true;
          //     break
          //   } else {
          //     continue;
          //   }
          // }
        }
      } else {
        console.log(6666666);
        // 直接点击左侧导航栏进来,展示第一天的metting
        let length = arr.length;
        for (let i = 0; i < length; i++) {
          let item = arr[i];
          if (i == 0) {
            item.actived = true;
            break;
          }
        }
      }
      if (this.bookingType == "Meetings") {
        this.date = arr;
      }
      this.showDatePick = true;
    },
    // 获取默认的room的date bar
    getDefaultRoomDate() {
      let d = new Date();
      d.setSeconds(0);
      let arr = [];
      let config = JSON.parse(localStorage.config);
      let displayBookingRoomLimit = config.displayBookingRoomLimit || 7;
      for (let i = 0; i < displayBookingRoomLimit; i++) {
        let obj = {};
        let timezone = localStorage.getItem("timezone");
        if (timezone) {
          obj.timeStampStart = moment
            .tz(d, timezone)
            .startOf("day")
            .add(+i, "days")
            .unix();
          obj.timeStampEnd = moment
            .tz(d, timezone)
            .endOf("day")
            .add(+i, "days")
            .unix();
          obj.day = moment
            .tz(d, timezone)
            .add(+i, "days")
            .format("ddd");
          obj.month = moment
            .tz(d, timezone)
            .add(+i, "days")
            .format("MMM");
          obj.date = moment
            .tz(d, timezone)
            .add(+i, "days")
            .format("DD");
        } else {
          obj.timeStampStart = moment(d)
            .startOf("day")
            .add(+i, "days")
            .unix();
          obj.timeStampEnd = moment(d)
            .endOf("day")
            .add(+i, "days")
            .unix();
          obj.day = moment(d)
            .add(+i, "days")
            .format("ddd");
          obj.month = moment(d)
            .add(+i, "days")
            .format("MMM");
          obj.date = moment(d)
            .add(+i, "days")
            .format("DD");
        }
        // obj.timeStampStart = moment(d).startOf('day').add(+(i), 'days').unix();
        // obj.timeStampEnd = moment(d).endOf('day').add(+(i), 'days').unix();
        // obj.day = (moment(d).add(+(i), 'days').format("ddd"));
        // obj.month = moment(d).add(+(i), 'days').format("MMM");
        // obj.date = moment(d).add(+(i), 'days').format("DD");
        if (i == 0) {
          obj.actived = true;
        } else {
          obj.actived = false;
        }
        arr.push(obj);
      }
      if (this.bookingType == "Meetings") {
        this.date = arr;
      }
    },
    getDeskDate() {
      let arrs = [];
      let today = new Date();
      today.setSeconds(0);
      let todayStartUnix;
      if (this.timezone) {
        todayStartUnix = moment.tz(today, this.timezone).startOf("day").unix();
      } else {
        todayStartUnix = moment(today).startOf("day").unix();
      }
      // let todayStartUnix = moment(today).startOf('day').unix();
      console.log("====todayStartUnix =====", todayStartUnix);
      this.desksData.map((item) => {
        let utcDate = moment.utc(item.start);
        let localDate = moment(utcDate);
        let start;
        let end;
        if (this.timezone) {
          start = moment
            .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
            .unix();
          end = moment.tz(item.zeroTimeZoneUnixEnd, "X", this.timezone).unix();
        } else {
          start = moment
            .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
          end = moment
            .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
        }
        // let start = moment
        //   .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
        //   .unix();
        // let end = moment
        //   .tz(item.zeroTimeZoneUnixEnd, "X", this.timezone)
        //   .unix();
        console.log("====end=====", end);
        if (end >= todayStartUnix) {
          let days = moment(end, "X").diff(moment(start, "X"), "days");

          let arr = [];
          for (let i = 0; i < days + 1; i++) {
            //获取start到end之间的date
            let day;
            if (this.timezone) {
              day = moment
                .tz(start, "X", this.timezone)
                .startOf("day")
                .add(+i, "days")
                .format("YYYY-MM-DD");
            } else {
              day = moment(localDate)
                .startOf("day")
                .add(+i, "days")
                .format("YYYY-MM-DD");
            }
            // let day = moment(localDate).startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
            arr.push(day);
          }
          arrs.push(arr);
        } else {
        }
      });
      let dateAll = [];
      arrs.map((item) => {
        //将所有桌子订单的日期放在一起
        item.map((jtem) => {
          dateAll.push(jtem);
        });
      });
      console.log("dateAll:", dateAll);
      let dateAllDeDupe = [...new Set(dateAll)]; //去重
      console.log(
        "dateAllDeDupe~！~！~！~！~！~！~~！~~！~！~！:",
        dateAllDeDupe
      );
      if (dateAllDeDupe.length == 0) {
        console.log("====what the fuyck =====");
        return;
      }
      let formatDate = [];
      dateAllDeDupe.map((item, index) => {
        let obj = {};
        let timezone = localStorage.getItem("timezone");
        if (timezone) {
          obj.timeStampStart = moment.tz(item, timezone).startOf("day").unix();
          obj.timeStampEnd = moment.tz(item, timezone).endOf("day").unix();
        } else {
          obj.timeStampStart = moment(item).startOf("day").unix();
          obj.timeStampEnd = moment(item).endOf("day").unix();
        }
        // obj.timeStampStart = moment(item).startOf('day').unix();
        // obj.timeStampEnd = moment(item).endOf('day').unix();
        obj.day = moment(item).format("ddd");
        obj.month = String(moment(item).format("MMM"));
        obj.date = moment(item).format("DD");
        obj.actived = false;
        formatDate.push(obj);
      });
      // 排序
      formatDate.sort(this.creatCompare("timeStampStart"));

      // 高亮
      if (this.startTime && this.endTime) {
        console.log("aaaaaaaaaaaa");
        if (this.startTime > formatDate[formatDate.length - 1].timeStampEnd) {
          console.log("cccccccccc");
          formatDate[0].actived = true;
        } else if (
          this.startTime < formatDate[0].timeStampStart &&
          this.endTime < formatDate[0].timeStampStart
        ) {
          console.log("dddddddddd");
          formatDate[0].actived = true;
        } else {
          console.log("eeeeeeeeeeee");
          let diff = moment(formatDate[0].timeStampStart, "X").diff(
            moment(this.startTime, "X"),
            "minutes"
          );
          console.log(
            "formatDate[0].timeStampStart:",
            formatDate[0].timeStampStart
          );
          console.log("this.startTime:", this.startTime);
          console.log("diff:", diff);
          if (diff >= 0) {
            console.log("fffffffff");
            // 开始时间小于当天则高亮当天日期
            formatDate[0].actived = true;
          } else {
            console.log("ggggggggg");
            // 开始时间大于当天则高亮开始时间
            let u;
            let timezone = localStorage.getItem("timezone");
            if (timezone) {
              u = moment
                .tz(this.startTime, "X", timezone)
                .startOf("day")
                .unix();
            } else {
              u = moment(this.startTime, "X").startOf("day").unix();
            }
            // let u = moment(this.startTime, "X").startOf('day').unix();
            console.log("u:", u);
            let length = formatDate.length;
            for (let i = 0; i < length; i++) {
              let item = formatDate[i];
              console.log("item:", item);
              if (item.timeStampStart == u) {
                console.log("hhhhhhhhhhh");
                item.actived = true;
                break;
              } else {
                continue;
              }
            }
          }
        }
      } else {
        console.log("bbbbbbbbb");
        let length = formatDate.length;
        for (let i = 0; i < length; i++) {
          let item = formatDate[i];
          if (i == 0) {
            item.actived = true;
            break;
          }
        }
      }

      if (
        this.bookingType == "Desks" ||
        this.bookingType == "Desks / Offices"
      ) {
        this.date = formatDate;
      }
    },
    // 获取desk的date bar
    getDefaultDeskDate() {
      console.log("-----------getDefaultDeskDate--------");
      let arrs = [];
      let today = new Date();
      today.setSeconds(0);
      let todayStartUnix;
      if (this.timezone) {
        todayStartUnix = moment.tz(today, this.timezone).startOf("day").unix();
      } else {
        todayStartUnix = moment(today).startOf("day").unix();
      }
      console.log("todayStartUnix", todayStartUnix);
      console.log("this.desksData", this.desksData);
      // let todayStartUnix = moment(today).startOf('day').unix();
      this.desksData.map((item) => {
        console.log("desksData", item);
        let start;
        let end;
        if (this.timezone) {
          start = moment
            .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
            .unix();
          end = moment.tz(item.zeroTimeZoneUnixEnd, "X", this.timezone).unix();
        } else {
          start = moment
            .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
          end = moment
            .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
        }
        // let start = moment.tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan").unix();
        // let end = moment.tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan").unix();

        // let start = moment
        //   .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
        //   .unix();
        // let end = moment
        //   .tz(item.zeroTimeZoneUnixEnd, "X", this.timezone)
        //   .unix();
        let days = moment(end, "X").diff(moment(start, "X"), "days");
        let arr = [];
        console.log("start11", start);
        console.log("end11", end);
        if (end >= todayStartUnix) {
          console.log("==== 大于今天=====", item);
          for (let i = 0; i < days + 1; i++) {
            //获取start到end之间的date
            let day = moment
              .tz(start, "X", this.timezone)
              .startOf("day")
              .add(+i, "days")
              .format("YYYY-MM-DD");

            // let day = moment.tz(start, "X", "Africa/Abidjan").startOf('day').add(+(i), 'days').format('YYYY-MM-DD');//错误的

            // let day = moment.tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan").startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
            arr.push(day);
          }
          arrs.push(arr);
        } else {
          console.log("==== 小于今天=====", item);
        }
      });
      let dateAll = [];
      arrs.map((item) => {
        //将所有桌子订单的日期放在一起
        item.map((jtem) => {
          dateAll.push(jtem);
        });
      });
      console.log("dateAll:", dateAll);
      let dateAllDeDupe = [...new Set(dateAll)]; //去重
      console.log("dateAllDeDupe:", dateAllDeDupe);
      let formatDate = [];
      dateAllDeDupe.map((item, index) => {
        let obj = {};
        let timezone = localStorage.getItem("timezone");
        if (timezone) {
          obj.timeStampStart = moment.tz(item, timezone).startOf("day").unix();
          obj.timeStampEnd = moment.tz(item, timezone).endOf("day").unix();
        } else {
          obj.timeStampStart = moment(item).startOf("day").unix();
          obj.timeStampEnd = moment(item).endOf("day").unix();
        }
        // obj.timeStampStart = moment(item).startOf('day').unix();
        // obj.timeStampEnd = moment(item).endOf('day').unix();
        obj.day = moment(item).format("ddd");
        obj.month = String(moment(item).format("MMM"));
        obj.date = moment(item).format("DD");
        obj.actived = false;
        formatDate.push(obj);
      });
      // 排序
      formatDate.sort(this.creatCompare("timeStampStart"));

      // 高亮
      let length = formatDate.length;
      for (let i = 0; i < length; i++) {
        let item = formatDate[i];
        if (i == 0) {
          item.actived = true;
          break;
        }
      }

      if (
        this.bookingType == "Desks" ||
        this.bookingType == "Desks / Offices"
      ) {
        this.date = formatDate;
      }
    },

    // getDeskDate() {
    //   let arrs = [];
    //   let today = new Date();
    //   let todayStartUnix = moment(today).startOf('day').unix();
    //   console.log("====todayStartUnix =====", todayStartUnix)
    //   this.desksData.map(item => {
    //     let utcDate = moment.utc(item.start);
    //     let localDate = moment(utcDate);
    //     let start = moment(localDate).startOf('day').unix();
    //
    //     let utcDateEnd = moment.utc(item.end);
    //     let localDateEnd = moment(utcDateEnd);
    //     let end = moment(localDateEnd).startOf('day').unix();
    //     console.log("====end=====", end)
    //     if (end >= todayStartUnix) {
    //       let days = moment(end, "X").diff(moment(start, "X"), 'days');
    //
    //       let arr = [];
    //       for (let i = 0; i < days + 1; i++) {//获取start到end之间的date
    //         let day = moment(localDate).startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
    //         arr.push(day);
    //       }
    //       arrs.push(arr);
    //     } else {
    //     }
    //   })
    //   let dateAll = [];
    //   arrs.map(item => {//将所有桌子订单的日期放在一起
    //     item.map(jtem => {
    //       dateAll.push(jtem);
    //     })
    //   })
    //   console.log('dateAll:', dateAll);
    //   let dateAllDeDupe = [...new Set(dateAll)];//去重
    //   console.log('dateAllDeDupe~！~！~！~！~！~！~~！~~！~！~！:', dateAllDeDupe);
    //   if (dateAllDeDupe.length == 0) {
    //     console.log("====what the fuyck =====",)
    //     return
    //   }
    //   let formatDate = [];
    //   dateAllDeDupe.map((item, index) => {
    //     let obj = {};
    //     let timezone = localStorage.getItem("timezone");
    //     if(timezone){
    //       obj.timeStampStart = moment.tz(item,timezone).startOf('day').unix();
    //       obj.timeStampEnd = moment.tz(item,timezone).endOf('day').unix();
    //     }else{
    //       obj.timeStampStart = moment(item).startOf('day').unix();
    //       obj.timeStampEnd = moment(item).endOf('day').unix();
    //     }
    //     // obj.timeStampStart = moment(item).startOf('day').unix();
    //     // obj.timeStampEnd = moment(item).endOf('day').unix();
    //     obj.day = (moment(item).format("ddd"));
    //     obj.month = String(moment(item).format("MMM"));
    //     obj.date = moment(item).format("DD");
    //     obj.actived = false;
    //     formatDate.push(obj);
    //   })
    //   // 排序
    //   formatDate.sort(this.creatCompare("timeStampStart"));
    //
    //   // 高亮
    //   if (this.startTime && this.endTime) {
    //     console.log('aaaaaaaaaaaa',);
    //     if (this.startTime > formatDate[formatDate.length - 1].timeStampEnd) {
    //       console.log('cccccccccc',);
    //       formatDate[0].actived = true;
    //     } else if (this.startTime < formatDate[0].timeStampStart && this.endTime < formatDate[0].timeStampStart) {
    //       console.log('dddddddddd',);
    //       formatDate[0].actived = true;
    //     } else {
    //       console.log('eeeeeeeeeeee',);
    //       let diff = moment(formatDate[0].timeStampStart, "X").diff(moment(this.startTime, "X"), "minutes");
    //       if (diff >= 0) {
    //         console.log('fffffffff',);
    //         // 开始时间小于当天则高亮当天日期
    //         formatDate[0].actived = true;
    //       } else {
    //         console.log('ggggggggg',);
    //         // 开始时间大于当天则高亮开始时间
    //         let u
    //         let timezone = localStorage.getItem("timezone");
    //         if(timezone){
    //           u = moment.tz(this.startTime, "X",timezone).startOf('day').unix();
    //         }else{
    //           u = moment(this.startTime, "X").startOf('day').unix();
    //         }
    //         // let u = moment(this.startTime, "X").startOf('day').unix();
    //         console.log("u:", u);
    //         let length = formatDate.length;
    //         for (let i = 0; i < length; i++) {
    //           let item = formatDate[i];
    //           console.log('item:',item);
    //           if (item.timeStampStart == u) {
    //             console.log('hhhhhhhhhhh',);
    //             item.actived = true;
    //             break
    //           } else {
    //             continue
    //           }
    //         }
    //       }
    //     }
    //   } else {
    //     console.log('bbbbbbbbb',);
    //     let length = formatDate.length;
    //     for (let i = 0; i < length; i++) {
    //       let item = formatDate[i];
    //       if (i == 0) {
    //         item.actived = true;
    //         break;
    //       }
    //     }
    //   }
    //
    //   if (this.bookingType == 'Desks' || this.bookingType == 'Desks / Offices') {
    //     this.date = formatDate;
    //   }
    // },
    // getDefaultDeskDate() {
    //   let arrs = [];
    //   let today = new Date();
    //   let todayStartUnix = moment(today).startOf('day').unix();
    //   this.desksData.map(item => {
    //     let utcDate = moment.utc(item.start);
    //     let localDate = moment(utcDate);
    //     let start = moment(localDate).startOf('day').unix();
    //
    //     let utcDateEnd = moment.utc(item.end);
    //     let localDateEnd = moment(utcDateEnd);
    //     let end = moment(localDateEnd).startOf('day').unix();
    //
    //
    //     let days = moment(end, "X").diff(moment(start, "X"), 'days');
    //
    //     let arr = [];
    //     if (end >= todayStartUnix) {
    //       console.log("==== 大于今天=====", item)
    //       for (let i = 0; i < days + 1; i++) {//获取start到end之间的date
    //         let day = moment(localDate).startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
    //         arr.push(day);
    //       }
    //       arrs.push(arr);
    //     } else {
    //       console.log("==== 小于今天=====", item)
    //     }
    //   })
    //   let dateAll = [];
    //   arrs.map(item => {//将所有桌子订单的日期放在一起
    //     item.map(jtem => {
    //       dateAll.push(jtem);
    //     })
    //   })
    //   console.log('dateAll:', dateAll);
    //   let dateAllDeDupe = [...new Set(dateAll)];//去重
    //   console.log('dateAllDeDupe:', dateAllDeDupe);
    //   let formatDate = [];
    //   dateAllDeDupe.map((item, index) => {
    //     let obj = {};
    //     let timezone = localStorage.getItem("timezone");
    //     if(timezone){
    //       obj.timeStampStart = moment.tz(item,timezone).startOf('day').unix();
    //       obj.timeStampEnd = moment.tz(item,timezone).endOf('day').unix();
    //     }else{
    //       obj.timeStampStart = moment(item).startOf('day').unix();
    //       obj.timeStampEnd = moment(item).endOf('day').unix();
    //     }
    //     // obj.timeStampStart = moment(item).startOf('day').unix();
    //     // obj.timeStampEnd = moment(item).endOf('day').unix();
    //     obj.day = (moment(item).format("ddd"));
    //     obj.month = String(moment(item).format("MMM"));
    //     obj.date = moment(item).format("DD");
    //     obj.actived = false;
    //     formatDate.push(obj);
    //   })
    //   // 排序
    //   formatDate.sort(this.creatCompare("timeStampStart"));
    //
    //   // 高亮
    //   let length = formatDate.length;
    //   for (let i = 0; i < length; i++) {
    //     let item = formatDate[i];
    //     if (i == 0) {
    //       item.actived = true;
    //       break;
    //     }
    //   }
    //
    //   if (this.bookingType == 'Desks' || this.bookingType == 'Desks / Offices') {
    //     this.date = formatDate;
    //   }
    // },
    // 获取帮别人预订的desk/room（目前只需要desk）
    // 获取desk的date bar
    // getOthersDate() {
    //   let arrs = [];
    //   let today = new Date();
    //   let todayStartUnix = moment(today).startOf('day').unix();
    //   console.log("====todayStartUnix =====", todayStartUnix)
    //   this.othersData.map(item => {
    //     let utcDate = moment.utc(item.start);
    //     let localDate = moment(utcDate);
    //     let start = moment(localDate).startOf('day').unix();
    //
    //     let utcDateEnd = moment.utc(item.end);
    //     let localDateEnd = moment(utcDateEnd);
    //     let end = moment(localDateEnd).startOf('day').unix();
    //     console.log("====end=====", end)
    //     if (end >= todayStartUnix) {
    //       let days = moment(end, "X").diff(moment(start, "X"), 'days');
    //
    //       let arr = [];
    //       for (let i = 0; i < days + 1; i++) {//获取start到end之间的date
    //         let day = moment(localDate).startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
    //         arr.push(day);
    //       }
    //       arrs.push(arr);
    //     } else {
    //     }
    //   })
    //   let dateAll = [];
    //   arrs.map(item => {//将所有桌子订单的日期放在一起
    //     item.map(jtem => {
    //       dateAll.push(jtem);
    //     })
    //   })
    //   console.log('dateAll:', dateAll);
    //   let dateAllDeDupe = [...new Set(dateAll)];//去重
    //   console.log('dateAllDeDupe:', dateAllDeDupe);
    //   let formatDate = [];
    //   dateAllDeDupe.map((item, index) => {
    //     let obj = {};
    //     let timezone = localStorage.getItem("timezone");
    //     if (timezone) {
    //       obj.timeStampStart = moment.tz(item, timezone).startOf('day').unix();
    //       obj.timeStampEnd = moment.tz(item, timezone).endOf('day').unix();
    //     } else {
    //       obj.timeStampStart = moment(item).startOf('day').unix();
    //       obj.timeStampEnd = moment(item).endOf('day').unix();
    //     }
    //     // obj.timeStampStart = moment(item).startOf('day').unix();
    //     // obj.timeStampEnd = moment(item).endOf('day').unix();
    //     obj.day = (moment(item).format("ddd"));
    //     obj.month = String(moment(item).format("MMM"));
    //     obj.date = moment(item).format("DD");
    //     obj.actived = false;
    //     formatDate.push(obj);
    //   })
    //   // 排序
    //   formatDate.sort(this.creatCompare("timeStampStart"));
    //
    //   // 高亮
    //   if (this.startTime && this.endTime) {
    //     if (this.startTime > formatDate[formatDate.length - 1].timeStampEnd) {
    //       formatDate[0].actived = true;
    //     } else if (this.startTime < formatDate[0].timeStampStart && this.endTime < formatDate[0].timeStampStart) {
    //       formatDate[0].actived = true;
    //     } else {
    //       let diff = moment(formatDate[0].timeStampStart, "X").diff(moment(this.startTime, "X"), "minutes");
    //       if (diff >= 0) {
    //         // 开始时间小于当天则高亮当天日期
    //         formatDate[0].actived = true;
    //       } else {
    //         // 开始时间大于当天则高亮开始时间
    //         let u = moment(this.startTime, "X").startOf('day').unix();
    //         console.log("u:", u);
    //         let length = formatDate.length;
    //         for (let i = 0; i < length; i++) {
    //           let item = formatDate[i];
    //           if (item.timeStampStart == u) {
    //             item.actived = true;
    //             break
    //           } else {
    //             continue
    //           }
    //         }
    //       }
    //     }
    //   } else {
    //     let length = formatDate.length;
    //     for (let i = 0; i < length; i++) {
    //       let item = formatDate[i];
    //       if (i == 0) {
    //         item.actived = true;
    //         break;
    //       }
    //     }
    //   }
    //
    //   if (this.bookingType == 'Others') {
    //     this.date = formatDate;
    //   }
    // },
    getOthersDate() {
      let timezone = localStorage.getItem("timezone");
      let arrs = [];
      let today = new Date();
      today.setSeconds(0);
      let todayStartUnix;
      if (this.timezone) {
        todayStartUnix = moment.tz(today, this.timezone).startOf("day").unix();
      } else {
        todayStartUnix = moment(today).startOf("day").unix();
      }
      // let todayStartUnix = moment(today).startOf('day').unix();
      console.log("====todayStartUnix =====", todayStartUnix);
      if (this.othersData.length <= 0) {
        return;
      }
      this.othersData.map((item) => {
        let utcDate = moment.utc(item.start);
        let localDate = moment(utcDate);
        let start;
        let end;
        if (this.timezone) {
          start = moment
            .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
            .unix();
          end = moment.tz(item.zeroTimeZoneUnixEnd, "X", this.timezone).unix();
        } else {
          start = moment
            .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
          end = moment
            .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
        }
        // let start = moment
        //   .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
        //   .unix();
        // let end = moment
        //   .tz(item.zeroTimeZoneUnixEnd, "X", this.timezone)
        //   .unix();
        console.log("====start=====", start);
        console.log("====end=====", end);
        if (end >= todayStartUnix) {
          let days = moment(end, "X").diff(moment(start, "X"), "days");

          let arr = [];
          for (let i = 0; i < days + 1; i++) {
            //获取start到end之间的date
            let day;
            if (this.timezone) {
              day = moment
                .tz(start, "X", timezone)
                .startOf("day")
                .add(+i, "days")
                .format("YYYY-MM-DD");
            } else {
              day = moment(localDate)
                .startOf("day")
                .add(+i, "days")
                .format("YYYY-MM-DD");
            }
            console.log("day::", day);
            // let day = moment(localDate).startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
            arr.push(day);
          }
          arrs.push(arr);
        }
      });
      let dateAll = [];
      arrs.map((item) => {
        //将所有桌子订单的日期放在一起
        item.map((jtem) => {
          dateAll.push(jtem);
        });
      });
      console.log("dateAll:", dateAll);
      let dateAllDeDupe = [...new Set(dateAll)]; //去重
      console.log("dateAllDeDupe:", dateAllDeDupe);
      let formatDate = [];
      if (dateAllDeDupe.length > 0) {
        dateAllDeDupe.map((item, index) => {
          let obj = {};
          let timezone = localStorage.getItem("timezone");
          if (timezone) {
            obj.timeStampStart = moment
              .tz(item, timezone)
              .startOf("day")
              .unix();
            obj.timeStampEnd = moment.tz(item, timezone).endOf("day").unix();
            obj.day = moment.tz(item, timezone).format("ddd");
            obj.month = String(moment.tz(item, timezone).format("MMM"));
            obj.date = moment.tz(item, timezone).format("DD");
          } else {
            obj.timeStampStart = moment(item).startOf("day").unix();
            obj.timeStampEnd = moment(item).endOf("day").unix();
            obj.day = moment(item).format("ddd");
            obj.month = String(moment(item).format("MMM"));
            obj.date = moment(item).format("DD");
          }
          // obj.timeStampStart = moment(item).startOf('day').unix();
          // obj.timeStampEnd = moment(item).endOf('day').unix();

          // obj.day = (moment(item).format("ddd"));
          // obj.month = String(moment(item).format("MMM"));
          // obj.date = moment(item).format("DD");
          obj.actived = false;
          formatDate.push(obj);
        });
      }
      // 排序
      formatDate.sort(this.creatCompare("timeStampStart"));

      // 高亮
      if (this.startTime && this.endTime) {
        if (this.startTime > formatDate[formatDate.length - 1].timeStampEnd) {
          formatDate[0].actived = true;
        } else if (
          this.startTime < formatDate[0].timeStampStart &&
          this.endTime < formatDate[0].timeStampStart
        ) {
          formatDate[0].actived = true;
        } else {
          let diff = moment(formatDate[0].timeStampStart, "X").diff(
            moment(this.startTime, "X"),
            "minutes"
          );
          if (diff >= 0) {
            // 开始时间小于当天则高亮当天日期
            formatDate[0].actived = true;
          } else {
            // 开始时间大于当天则高亮开始时间
            let u;
            if (timezone) {
              u = moment
                .tz(this.startTime, "X", timezone)
                .startOf("day")
                .unix();
            } else {
              u = moment(this.startTime, "X").startOf("day").unix();
            }
            console.log("u:", u);
            let length = formatDate.length;
            for (let i = 0; i < length; i++) {
              let item = formatDate[i];
              if (item.timeStampStart == u) {
                item.actived = true;
                break;
              } else {
                continue;
              }
            }
          }
        }
      } else {
        let length = formatDate.length;
        for (let i = 0; i < length; i++) {
          let item = formatDate[i];
          if (i == 0) {
            item.actived = true;
            break;
          }
        }
      }

      if (this.bookingType == "Others") {
        this.date = formatDate;
      }
    },
    getDefaultOtherDate() {
      let arrs = [];
      let today = new Date();
      today.setSeconds(0);
      let todayStartUnix;
      if (this.timezone) {
        todayStartUnix = moment.tz(today, this.timezone).startOf("day").unix();
      } else {
        todayStartUnix = moment(today).startOf("day").unix();
      }
      // let todayStartUnix = moment(today).startOf('day').unix();
      console.log("getDefaultOtherDate-todayStartUnix", todayStartUnix);
      this.othersData.map((item) => {
        console.log("othersData-item", item);
        let start;
        let end;
        if (this.timezone) {
          start = moment
            .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
            .unix(); //先把后台返回来的0时区的时间字符串转换成unix，再用unix转换成campus下timezone的unix
          end = moment.tz(item.zeroTimeZoneUnixEnd, "X", this.timezone).unix();
        } else {
          start = moment
            .tz(item.start, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
          end = moment
            .tz(item.end, "YYYY-MM-DD HH:mm:ss", "Africa/Abidjan")
            .unix();
        }
        // let start = moment
        //   .tz(item.zeroTimeZoneUnixStart, "X", this.timezone)
        //   .unix(); //先把后台返回来的0时区的时间字符串转换成unix，再用unix转换成campus下timezone的unix
        // let end = moment
        //   .tz(item.zeroTimeZoneUnixEnd, "X", this.timezone)
        //   .unix();
        let days = moment(end, "X").diff(moment(start, "X"), "days");
        let arr = [];
        console.log("start", start);
        console.log("end", end);
        if (end >= todayStartUnix) {
          console.log("==== 大于今天=====", item);
          for (let i = 0; i < days + 1; i++) {
            //获取start到end之间的date
            let day = moment
              .tz(start, "X", this.timezone)
              .startOf("day")
              .add(+i, "days")
              .format("YYYY-MM-DD");
            arr.push(day);
            console.log("day", day);
          }
          arrs.push(arr);
        } else {
          console.log("==== 小于今天=====", item);
        }
      });
      let dateAll = [];
      arrs.map((item) => {
        //将所有桌子订单的日期放在一起
        item.map((jtem) => {
          dateAll.push(jtem);
        });
      });
      console.log("dateAll:", dateAll);
      let dateAllDeDupe = [...new Set(dateAll)]; //去重
      console.log("dateAllDeDupe:", dateAllDeDupe);
      let formatDate = [];
      dateAllDeDupe.map((item, index) => {
        let obj = {};
        let timezone = localStorage.getItem("timezone");
        if (timezone) {
          obj.timeStampStart = moment.tz(item, timezone).startOf("day").unix();
          obj.timeStampEnd = moment.tz(item, timezone).endOf("day").unix();
          obj.day = moment.tz(item, timezone).format("ddd");
          obj.month = String(moment.tz(item, timezone).format("MMM"));
          obj.date = moment.tz(item, timezone).format("DD");
        } else {
          obj.timeStampStart = moment(item).startOf("day").unix();
          obj.timeStampEnd = moment(item).endOf("day").unix();
          obj.day = moment(item).format("ddd");
          obj.month = String(moment(item).format("MMM"));
          obj.date = moment(item).format("DD");
        }
        // obj.timeStampStart = moment(item).startOf('day').unix();
        // obj.timeStampEnd = moment(item).endOf('day').unix();
        // obj.day = (moment(item).format("ddd"));
        // obj.month = String(moment(item).format("MMM"));
        // obj.date = moment(item).format("DD");
        obj.actived = false;
        formatDate.push(obj);
      });
      // 排序
      formatDate.sort(this.creatCompare("timeStampStart"));

      // 高亮
      let length = formatDate.length;
      for (let i = 0; i < length; i++) {
        let item = formatDate[i];
        if (i == 0) {
          item.actived = true;
          break;
        }
      }

      if (this.bookingType == "Others") {
        this.date = formatDate;
      }
    },

    // getDefaultOtherDate() {
    //   let arrs = [];
    //   let today = new Date();
    //   let todayStartUnix = moment(today).startOf('day').unix();
    //   this.othersData.map(item => {
    //     console.log("==== item~~~~~~~~~~~~~~~~~~~~~=====", item)
    //     let utcDate = moment.utc(item.start);
    //     let localDate = moment(utcDate);
    //     let start = moment(localDate).startOf('day').unix();
    //
    //     let utcDateEnd = moment.utc(item.end);
    //     let localDateEnd = moment(utcDateEnd);
    //     let end = moment(localDateEnd).startOf('day').unix();
    //
    //
    //     let days = moment(end, "X").diff(moment(start, "X"), 'days');
    //
    //     let arr = [];
    //     if (end >= todayStartUnix) {
    //       console.log("==== 大于今天=====", item)
    //       for (let i = 0; i < days + 1; i++) {//获取start到end之间的date
    //         let day = moment(localDate).startOf('day').add(+(i), 'days').format('YYYY-MM-DD');
    //         arr.push(day);
    //       }
    //       arrs.push(arr);
    //     } else {
    //       console.log("==== 小于今天=====", item)
    //     }
    //   })
    //   let dateAll = [];
    //   arrs.map(item => {//将所有桌子订单的日期放在一起
    //     item.map(jtem => {
    //       dateAll.push(jtem);
    //     })
    //   })
    //   console.log('dateAll:', dateAll);
    //   let dateAllDeDupe = [...new Set(dateAll)];//去重
    //   console.log('dateAllDeDupe:', dateAllDeDupe);
    //   let formatDate = [];
    //   dateAllDeDupe.map((item, index) => {
    //     let obj = {};
    //     let timezone = localStorage.getItem("timezone");
    //     if (timezone) {
    //       obj.timeStampStart = moment.tz(item, timezone).startOf('day').unix();
    //       obj.timeStampEnd = moment.tz(item, timezone).endOf('day').unix();
    //     } else {
    //       obj.timeStampStart = moment(item).startOf('day').unix();
    //       obj.timeStampEnd = moment(item).endOf('day').unix();
    //     }
    //     // obj.timeStampStart = moment(item).startOf('day').unix();
    //     // obj.timeStampEnd = moment(item).endOf('day').unix();
    //     obj.day = (moment(item).format("ddd"));
    //     obj.month = String(moment(item).format("MMM"));
    //     obj.date = moment(item).format("DD");
    //     obj.actived = false;
    //     formatDate.push(obj);
    //   })
    //   // 排序
    //   formatDate.sort(this.creatCompare("timeStampStart"));
    //
    //   // 高亮
    //   let length = formatDate.length;
    //   for (let i = 0; i < length; i++) {
    //     let item = formatDate[i];
    //     if (i == 0) {
    //       item.actived = true;
    //       break;
    //     }
    //   }
    //
    //   if (this.bookingType == 'Others') {
    //     this.date = formatDate;
    //   }
    // },
    // 对象数组中根据选定属性大小排序
    creatCompare(propertyName) {
      return function (obj1, obj2) {
        var value1 = obj1[propertyName];
        var value2 = obj2[propertyName];
        if (value1 < value2) {
          return -1;
        } else if (value1 > value2) {
          return 1;
        } else {
          return 0;
        }
      };
    },
    refreshRoom(attendees, data) {
      console.log("refreshRoom-attendees", attendees);
      console.log("refreshRoom-data", data);
      console.log("currentDesk:", this.currentDesk);
      let at = JSON.parse(JSON.stringify(attendees)); //这里踩了坑了，注意一定要深拷贝
      this.currentDesk.summary = data.summary;
      this.currentDesk.description = data.description;
      this.currentDesk.itemStartUnix = data.start;
      this.currentDesk.itemEndUnix = data.end;
      this.currentDesk.attendees = at;
      this.currentDesk.startTime = data.startTime;
      this.currentDesk.endTime = data.endTime;
      this.currentDesk.description = data.description;
      // this.currentDesk.startTime = data.end;
      // this.currentDesk.endTime = moment.utc(moment(data.end,"x"));
      this.bus.$emit("loading", false);
      this.$message.success("Success");
      this.$refs.bookingMap.showBookingList = true;
      let timezone = localStorage.getItem("timezone");
      if (timezone) {
        this.$nextTick(() => {
          this.$refs.bookingMap.startTime = moment
            .tz(data.start, "X", timezone)
            .format("MMM D, h:mm a");
          this.$refs.bookingMap.endTime = moment
            .tz(data.end, "X", timezone)
            .format("MMM D, h:mm a");
        });
      } else {
        this.$nextTick(() => {
          this.$refs.bookingMap.startTime = moment(data.start, "X").format(
            "MMM D, h:mm a"
          );
          this.$refs.bookingMap.endTime = moment(data.end, "X").format(
            "MMM D, h:mm a"
          );
        });
      }
    },
    // refreshRoom(eventId) {
    //   console.log("eventId:", eventId);
    //   this.listEventReturn().then(res => {
    //     console.log("~~~~~res", res)
    //     // this.showList = true;
    //     // this.roomsData = [];
    //     // this.roomsData = res.data.data;
    //     let meetingId = localStorage.getItem('meetingIdMap');
    //     let calendarId = localStorage.getItem('userEmail');
    //     let eventId = '';
    //     let length = res.data.length;
    //     for (let i = 0; i < length; i++) {
    //       let item = res.data[i];
    //       if (item.rooms[0].resourceId == eventId) {
    //         eventId = item.rooms[0].resourceId;
    //         break
    //       }
    //     }
    //
    //     this.getEvent(meetingId, calendarId, eventId).then(res => {
    //       let attendees = res.data.data.attendees;
    //       this.roomsData = [];
    //       this.roomsData = res.data.data;
    //       let length = this.roomsData.length;
    //       for (let i = 0; i < length; i++) {
    //         let item = this.roomsData[i];
    //         if (eventId == item.eventId) {
    //           this.currentDesk = item;
    //           this.currentDesk.attendees = attendees;
    //           break
    //         } else {
    //           continue
    //         }
    //       }
    //       this.formatMeetingData();
    //       this.bus.$emit('loading', false);
    //       this.$message.success("Success");
    //       this.$refs.bookingMap.showBookingList = true;
    //     }).catch(err => {
    //       this.bus.$emit('loading', false);
    //     })
    //
    //   });
    // },
    // initData() {
    //   this.bus.$emit('loading', true);
    //   let that = this;
    //   let fun1 = function () {
    //     return new Promise((resolve, reject) => {
    //       /* 你的逻辑代码 */
    //       let data = that.listEventReturn();
    //       // let freeBusy = that.freeBusyReturn(startUnix, endUnix);
    //       resolve(data);
    //     });
    //   }
    //   let fun2 = function () {
    //     return new Promise((resolve, reject) => {
    //       /* 你的逻辑代码 */
    //       let data = that.getUserSeatsReturn()
    //       resolve(data)
    //     });
    //   }
    //   let run = function () {
    //     Promise.all([
    //       fun1(),
    //       fun2(),
    //     ]).then(res => {
    //       that.firstIn = false;
    //       that.roomsData = res[0].data.data;
    //       that.formatMeetingData()
    //
    //       that.desksData = res[1].data.data;
    //       console.log("desksData:", that.desksData)
    //       console.log("initData")
    //       that.getDeskDate();
    //       that.formatDeskData();
    //       that.bus.$emit('loading', false);
    //       that.stopInterval();
    //       that.startInterval();
    //     }).catch(err => {
    //       that.bus.$emit('loading', false);
    //       console.log(err);
    //     })
    //   }
    //   run();
    // },
    initData() {
      this.bus.$emit("loading", true);
      let that = this;
      let fun1 = function () {
        return new Promise((resolve, reject) => {
          let data = that.listEventReturn();
          console.log("fun1", data);
          resolve(data);
        });
      };
      let fun2 = function () {
        return new Promise((resolve, reject) => {
          let data = that.getUserSeatsReturn();
          console.log("fun2", data);
          resolve(data);
        });
      };
      let fun3 = function () {
        return new Promise((resolve, reject) => {
          let data = that.delegateBookingReturn();
          console.log("fun3", data);
          resolve(data);
        });
      };
      let run = function () {
        Promise.allSettled([fun1(), fun2(), fun3()])
          .then((res) => {
            console.log("======", res);
            console.log("desk", res[1].value.data.data);
            that.firstIn = false;
            if (res[0].status === "fulfilled") {
              console.log("res[0]", res[0]);
              console.log(
                "room-res[0].value.data.data",
                res[0].value.data.data
              );
              that.roomsData = res[0].value.data.data;
              console.log("初始roomsData", that.roomsData);
              if (that.roomsData && that.roomsData.length > 0) {
                that.changeTimezone("room"); //list中返回的字符串都是0时区的，要前端手动改成当前meeting所在时间的时间
              }
            } else {
              that.roomsData = [];
            }
            that.formatMeetingData();
            if (res[1].status === "fulfilled") {
              console.log("res[1]", res[1]);
              console.log(
                "desk-res[1].value.data.data",
                res[1].value.data.data
              );
              that.desksData = res[1].value.data.data;
              if (that.desksData && that.desksData.length > 0) {
                that.changeTimezone("desk"); //list中返回的字符串都是0时区的，要前端手动改成当前meeting所在时间的时间
              }
              // 将所有小于今天日期的desk去掉
              let today = new Date();
              let todayStartUnix;
              if (that.timezone) {
                todayStartUnix = moment
                  .tz(today, that.timezone)
                  .startOf("day")
                  .unix();
              } else {
                todayStartUnix = moment(today).startOf("day").unix();
              }
              that.desksData.map((item, index) => {
                console.log("item:", item);
                let utcDateEnd = moment.utc(item.end);
                let localDateEnd = moment(utcDateEnd);
                let end;
                if (that.timezone) {
                  end = moment
                    .tz(item.zeroTimeZoneUnixEnd, "X", that.timezone)
                    .startOf("day")
                    .unix();
                } else {
                  end = moment(localDateEnd).startOf("day").unix();
                }
                if (end < todayStartUnix) {
                  that.desksData.splice(index, 1);
                }
              });
              // that.desksData.map((item, index) => {
              //   console.log('item:',item);
              //   let utcDateEnd = moment.utc(item.end);
              //   let localDateEnd = moment(utcDateEnd);
              //   let end = moment(localDateEnd).startOf('day').unix();
              //   if (end < todayStartUnix) {
              //     that.desksData.splice(index, 1)
              //   }
              // })
            } else {
              that.desksData = [];
            }
            console.log("that.desksData!!!!!", that.desksData);
            that.getDeskDate();
            that.formatDeskData();
            if (res[2].status === "fulfilled") {
              console.log("res[2]!!!!!!!!!!!!!!", res[2]);
              if (res[2].value.data.data) {
                let arr = res[2].value.data.data;
                let arr2 = [];
                arr.map((item) => {
                  item.desk.actualUserName = item.actualUserName;
                  arr2.push(item.desk);
                });
                that.othersData = arr2;
                if (that.othersData && that.othersData.length > 0) {
                  that.changeTimezone("others"); //list中返回的字符串都是0时区的，要前端手动改成当前meeting所在时间的时间
                }
                console.log(
                  "====othersData!!!!!!!!!!!!!! =====",
                  that.othersData
                );
              }
            }
            that.getOthersDate();
            that.formatOtherData();
            that.bus.$emit("loading", false);
            that.stopInterval();
            that.startInterval();
          })
          .catch((err) => {
            that.bus.$emit("loading", false);
            console.log(err);
          });
      };
      run();
    },
    refreshAll() {
      this.bookingType = this.getBookingType;
      if (this.$route.params.start && this.$route.params.end) {
        this.startTime = this.$route.params.start;
        this.endTime = this.$route.params.end;
        this.firstIn = true;
      } else {
        this.firstIn = false;
        this.startTime = 0;
        this.endTime = 0;
      }
      this.getRoomDate();
      console.log("startTime:", this.startTime);
      console.log("endTime:", this.endTime);
      this.getCurrentDate();
      this.initData();
    },
    checkSelectShow() {
      let showRoomButton = JSON.parse(localStorage.getItem("showRoomButton"));
      let showDeskButton = JSON.parse(localStorage.getItem("showDeskButton"));
      if (showRoomButton) {
        console.log("111111111!!!!!!!");
        if (showDeskButton) {
          // if (localStorage.getItem('client') == 'NatWest' || localStorage.getItem('client') == 'Clorox'|| localStorage.getItem('client') == 'WarnerMedia'|| localStorage.getItem('client') == 'CX Campus') {
          //   this.bookingTypes = ['Meetings', 'Desks / Offices', 'Others']
          // }
          if (this.showOthers) {
            if (displayDesksOffice) {
              this.bookingTypes = ["Meetings", "Desks / Offices", "Others"];
            } else {
              // showDeskButton?this.bookingTypes= ['Meetings', 'Desks', 'Others']:this.bookingTypes=  ['Meetings'];
              this.bookingTypes = ["Meetings", "Desks", "Others"];
            }
          } else {
            // showDeskButton?this.bookingTypes= ['Meetings', 'Desks']:this.bookingTypes=  ['Meetings'];
            this.bookingTypes = ["Meetings", "Desks"];
          }
        } else {
          this.bookingTypes = ["Meetings"];
        }
      } else {
        console.log("2222222222!!!!!!!");
        if (showDeskButton) {
          console.log("3333333333333!!!!!!!");
          if (this.showOthers) {
            // this.bookingTypes = ['Desks / Offices', 'Others'];
            if (displayDesksOffice) {
              this.bookingTypes = ["Meetings", "Desks / Offices", "Others"];
            } else {
              this.bookingTypes = ["Meetings", "Desks", "Others"];
            }
          } else {
            this.bookingTypes = ["Desks"];
          }
        } else {
          this.bookingTypes = [];
        }
      }
    },
  },
  created() {
    console.log("this.getBookingType``````````````````", this.getBookingType);
    this.timezone = localStorage.getItem("timezone");
    this.client = localStorage.getItem("client");
    this.bookingType = this.getBookingType;
    this.showOthers = showDelegate;
    this.isDdisplayDesksOffice = displayDesksOffice;
    console.log("showOthersshowOthersshowOthers", this.showOthers);
    let showDeskButton = localStorage.getItem("showDeskButton");
    if (localStorage.getItem("jumpToBooking") == "true") {
      localStorage.removeItem("jumpToBooking");
    }
    if (localStorage.getItem("client") == "WarnerMedia") {
      showDeskButton
        ? (this.bookingTypes = ["Meetings", "Desks / Offices"])
        : (this.bookingTypes = ["Meetings"]);
      this.selectLabel = "Select a Reservation Type";
      this.tip = "Booking Reservation ";
      this.listWidth = "28rem";
    } else if (this.showOthers) {
      if (displayDesksOffice) {
        showDeskButton
          ? (this.bookingTypes = ["Meetings", "Desks / Offices", "Others"])
          : (this.bookingTypes = ["Meetings", "Others"]);
      } else {
        showDeskButton
          ? (this.bookingTypes = ["Meetings", "Desks", "Others"])
          : (this.bookingTypes = ["Meetings", "Others"]);
      }
      this.selectLabel = "Select a Booking Type";
      this.tip = "Booking Type ";
      this.listWidth = "25rem";
    } else {
      showDeskButton
        ? (this.bookingTypes = ["Meetings", "Desks"])
        : (this.bookingTypes = ["Meetings"]);
      this.selectLabel = "Select a Booking Type";
      this.tip = "Booking Type";
      this.listWidth = "25rem";
    }
    this.checkSelectShow();
    this.refreshAll();
  },
  destroyed() {
    this.setBookingType("");
    this.stopInterval();
  },
};
</script>

<style scoped lang="scss">
.booking {
  height: 100%;

  .top {
    //background: $main-blue;
    height: 35px;
    line-height: 35px;
    color: #fff;
    font-size: 1.2rem;
    text-align: center;
  }

  //.dateContent {
  //  min-height: 75px;
  //  padding-top: 10px;
  //  background: #f3f3f3;
  //
  //  .bookingType {
  //    width: 200px !important;
  //  }
  //
  //  .datePick {
  //  }
  //}
  .dateContent {
    min-height: 75px;
    padding-top: 10px;
    background: #f3f3f3;
    position: relative;

    .bookingType {
      width: 220px !important;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0%, -30%);
    }

    .datePick {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bookingTips {
    p {
      width: 450px;
      margin: 100px auto;
      text-align: center;
    }
  }

  .content {
    display: flex;
    background: #fff;
    //height: 75vh;
    //padding: 10px;
    //overflow: scroll;
    overflow: auto;

    .bookingList {
      margin: 10px;
    }
  }
}
</style>
<style>
.v-select-list {
  min-width: 220px;
}
</style>
